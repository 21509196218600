/* BoschSans Light */
@font-face {
    font-family: BoschSansLight;
    src: local(BoschSans-Light),
        url('../fonts/BoschSans-Light.woff2') format('woff2'),
        url('../fonts/BoschSans-Light.woff') format('woff'),
        url('../fonts/BoschSans-Light.ttf') format('truetype');
}

/* BoschSans Regular */
@font-face {
    font-family: BoschSansRegular;
    src: local(BoschSans-Regular),
        url('../fonts/BoschSans-Regular.woff2') format('woff2'),
        url('../fonts/BoschSans-Regular.woff') format('woff'),
        url('../fonts/BoschSans-Regular.ttf') format('truetype');
}

/* BoschSans Medium */
@font-face {
    font-family: BoschSansMedium;
    font-style: normal;
    src: local(BoschSans-Medium),
        url('../fonts/BoschSans-Medium.woff2') format('woff2'),
        url('../fonts/BoschSans-Medium.woff') format('woff'),
        url('../fonts/BoschSans-Medium.ttf') format('truetype');
}

/* BoschSans Bold */
@font-face {
    font-family: BoschSansBold;
    src: local(BoschSans-Bold),
        url('../fonts/BoschSans-Bold.woff2') format('woff2'),
        url('../fonts/BoschSans-Bold.woff') format('woff'),
        url('../fonts/BoschSans-Bold.ttf') format('truetype');
}

/* BoschSans Black */
@font-face {
    font-family: BoschSansBlack;
    src: local(BoschSans-Black),
        url('../fonts/BoschSans-Black.woff2') format('woff2'),
        url('../fonts/BoschSans-Black.woff') format('woff'),
        url('../fonts/BoschSans-Black.ttf') format('truetype');
}

.wbtTitle {
    color: #000000;
    font-size: 25px;
    line-height: 29px;
    font-family: BoschSansLight;
    font-weight: Light;
}
